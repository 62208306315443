var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-5"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"success","dismissible":""},model:{value:(_vm.registerSuccess),callback:function ($$v) {_vm.registerSuccess=$$v},expression:"registerSuccess"}},[_vm._v("Registration successfull.")]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.isRegisterError),callback:function ($$v) {_vm.isRegisterError=$$v},expression:"isRegisterError"}},[_vm._v(_vm._s(_vm.regError))]),(_vm.notification.message)?_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","show":"","dismissible":""}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.tryToRegisterIn.apply(null, arguments)}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-user-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.username),expression:"user.username"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.username.$error,
                            },attrs:{"type":"text","id":"username","placeholder":"Enter username"},domProps:{"value":(_vm.user.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "username", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"useremail"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                            },attrs:{"type":"email","id":"useremail","placeholder":"Enter email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_vm._m(2),_vm._m(3)])],1),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Already have an account ? "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"tag":"a","to":"/login"}},[_vm._v("Login")])],1),_vm._m(4)])])])])])])]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-dark.png"),"height":"20","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Register account")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Get your free Nazox account now. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Register ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" By registering you agree to the Nazox "),_c('a',{staticClass:"text-primary",attrs:{"href":"#"}},[_vm._v("Terms of Use")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" © 2020 Nazox. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Themesdesign ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }